import React from "react"
import { graphql, Link } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import styled from "styled-components"

const NotFoundPage = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout>
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <NotFound>
          <h1>404</h1>
          <h2>Page not found</h2>
          <Link to={`/${langKey}/`}>Back to Home</Link>
        </NotFound>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default NotFoundPage

const NotFound = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 72px;
    line-height: 76px;
  }

  h2 {
    font-size: 18px;
  }

  a {
    background: #0073af;
    color: #fff;
    border-radius: 20px;
    padding: 10px 30px;
    margin-top: 25px;
  }
`

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
